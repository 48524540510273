<template>
	<div class="app-container">
		<el-card class="mt-3">
			<el-row>
				<el-col :xs="24" :md="24">
					<el-steps :active="dataList.active" finish-status="success" :align-center="true" style="width:100%">
						<el-step :title="$t('admin_general.table_account_info')"></el-step>
						<el-step :title="$t('admin_general.table_personal_info')"></el-step>
					</el-steps>
				</el-col>
				
				<el-col :xs="24" :md="24" class="mt-5">
					<div v-if="dataList.active == 0">
						<el-card shadow="never" class="mb-3">
							<b-row>
								<b-col :xs="12" :sm="12" :md="6">
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_username')}}</template>
										<b-form-input v-model="dataList.username" :placeholder="$t('admin_general.table_username')" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_login_password')}}</template>
										<b-form-input v-model="dataList.password" :placeholder="$t('admin_general.table_login_password')" type="password" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_confirm_login_password')}}</template>
										<b-form-input v-model="dataList.confirm_password" :placeholder="$t('admin_general.table_confirm_login_password')" type="password" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<small class="text-danger">* {{$t('msg.msg_password_length')}}</small><br/>
									<small class="text-danger">* {{$t('msg.msg_password_format')}}</small>
								</b-col>
								
								<b-col :xs="12" :sm="12" :md="6">
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_job')}}</template>
										<el-select v-model="dataList.job_id" class="w-100" filterable :placeholder="$t('admin_general.table_job')" clearable @change="saveData()">
											<el-option v-for="(item,index) in jobList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
										</el-select>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_security_password')}}</template>
										<b-form-input v-model="dataList.security_password" :placeholder="$t('admin_general.table_security_password')" type="password" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_confirm_security_password')}}</template>
										<b-form-input v-model="dataList.confirm_security_password" :placeholder="$t('admin_general.table_confirm_security_password')" type="password" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<small class="text-danger">* {{$t('msg.msg_security_length')}}</small>
								</b-col>
							</b-row>
						</el-card>
						
						<b-row>
							<b-col :xs="12" :md="12" class="text-right">
								<el-button size="medium" type="primary" @click="nextPage(1)" :loading="buttonloading" icon="el-icon-arrow-right">{{$t('button.next')}}</el-button>
							</b-col>
						</b-row>
					</div>
					
					<div v-if="dataList.active == 1">
						<el-card shadow="never" class="mb-3">
							<b-row>
								<b-col :xs="12" :sm="12" :md="6">
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_name')}}</template>
										<b-form-input v-model="dataList.my_name" :placeholder="$t('admin_general.table_name')" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_email')}}</template>
										<b-form-input v-model="dataList.my_email" :placeholder="$t('admin_general.table_email')" class="w-100" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_gender')}}</template>
										<el-select filterable v-model="dataList.gender" :placeholder="$t('admin_general.table_gender')" class="w-100" @change="saveData()">
											<el-option value="m" :label="$t('general.male')"></el-option>
											<el-option value="f" :label="$t('general.female')"></el-option>
										</el-select>
									</b-form-group>
								</b-col>
								
								<b-col :xs="12" :sm="12" :md="6">
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_phone_mobile')}}</template>
										<b-form-input v-model="dataList.my_phone_mobile" :placeholder="$t('admin_general.table_phone_mobile')" class="w-100" @change="saveData()"></b-form-input>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">
											{{$t('admin_general.table_dob')}}
										</template>
										<el-date-picker v-model="dataList.date_of_birth" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :placeholder="$t('admin_general.table_dob')" class="w-100"></el-date-picker>
									</b-form-group>
									
									<b-form-group label-cols="12" label-cols-lg="4">
										<template slot="label">{{$t('admin_general.table_ic')}}</template>
										<b-form-input v-model="dataList.icpass" :placeholder="$t('admin_general.table_ic')"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
						</el-card>
						
						<el-card shadow="never" class="mb-3" v-if="this.branchSelect.includes(dataList.job_id)">
							<b-form-group label-cols="12" label-cols-lg="12">
								<template slot="label">{{$t('admin_general.table_belong_branch')}}</template>
								<el-checkbox-group v-model="dataList.branch_id" class="mt-2" :max="(dataList.job_id == '3') ? 1 : 99 ">
									<el-checkbox v-for="(item,index) in branchList" :key="index" :label="item.id" >{{item.name}}</el-checkbox>
								</el-checkbox-group>
							</b-form-group>
						</el-card>
						
						<b-row>
							<b-col :xs="6" :md="6">
								<el-button size="medium" type="primary" @click="prevPage(0)" :loading="buttonloading" icon="el-icon-arrow-left">{{$t('button.prev')}}</el-button>
							</b-col>
							
							<b-col :xs="6" :md="6" class="text-right">
								<el-button size="medium" type="success" :loading="buttonloading" icon="el-icon-position" @click="addRow">{{$t('button.confirm')}}</el-button>
							</b-col>
						</b-row>
					</div>
				</el-col>
			</el-row>
		</el-card>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import backtotop from '@/components/backtotop';

let postForm = {
	active: 0,
	username: '',
	password:'',
	job_id: '',
	branch_id: [],
	confirm_password:'',
	security_password:'',
	confirm_security_password:'',
	my_name:'',
	my_email:'',
	my_phone_mobile:'',
	date_of_birth:'',
	icpass:'',
	gender:'m',
	my_security:''
}

export default{
	components: {backtotop},
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			errors: [],
			dataList:Object.assign({}, postForm),
			registerRefresh:0,
			jobList:[],
			branchList:[],
			permissionList:[],
			languageList:[],
			permission:{
                index:'VLDLDK',
                DBadd:'U4C2U2'
            },
			branchSelect:['3','7'],
			postData: {
				data: '',
				language: ''
			}
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var self = this;
				var result = postMethod('admin/register/index',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.registerRefresh = data.registerRefresh;
						self.jobList = data.jobList;
						self.dataList.job_id = data.jobList[0].id;
						self.branchList = data.branchList;
						self.buttonloading = false;
						self.loading = false;
						
						self.saveData();
					}
				});
			}
		},nextPage(page){
			this.errors = [];
		
			if(this.errors.length > 0){
				var text = '';
				this.errors.forEach(function (value){
					text+= value+"<br/>";
				});
				
				this.$method.popupErrorMessage(this, text);
			}else{
				if(page < 2){
					this.dataList.active = page;
				}
			}
			
			return (this.errors.length > 0) ? 0 : 1;
		},prevPage(page){
			this.dataList.active = page;
		},reset(){
			this.dataList.active = 0;
			this.dataList.username = '';
			this.dataList.password = '';
			this.dataList.confirm_password = '';
			this.dataList.security_password = '';
			this.dataList.confirm_security_password = '';
			this.dataList.register_type = 'personal';
			this.dataList.my_name = '';
			this.dataList.my_email = '';
			this.dataList.my_phone_mobile = '';
			this.dataList.my_address = '';
			this.dataList.my_postcode = '';
			this.dataList.date_of_birth = '';
			this.dataList.icpass = '';
			this.dataList.gender = 'm';
			this.dataList.my_security = '';
			this.dataList.branch_id = [];
			
			this.initial();
			this.saveData();
		},addRow(){
			var result = this.nextPage(2);
			
			if(this.permissionList.includes(this.permission.DBadd) && result == 1){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.preloader(true);
					this.dataList.my_security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.dataList));
					var result = postMethod('admin/register/DBadd',this.postData);

					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								message: data.returnMsg,
								type: 'success'
							});

							self.$bvModal.hide('addRow');
							if(self.registerRefresh == 1){
								self.reset();
							}
						}else{					
							data.returnMsg.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self,text);
						}
						
						self.buttonloading = false;
						self.preloader(false);
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},saveData(){
			postForm = this.dataList;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>